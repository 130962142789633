import PropTypes from 'prop-types';
import InformationDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/InformationDataTransformerBlock';
import { styled } from '@activebrands/core-web/libs/styletron';

const InformationWrapper = styled('section', ({ $isGrid }) => ({
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
}));

const InformationModule = ({ isGrid = false, ...rest }) => {
    return (
        <InformationWrapper $isGrid={isGrid}>
            <InformationDataTransformerBlock {...rest} />
        </InformationWrapper>
    );
};

InformationModule.propTypes = {
    isGrid: PropTypes.bool,
};

export default InformationModule;
